import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";

const FilesContext = createContext();

export const FilesDataProvider = ({ children }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [filesData, setFilesData] = useState([]);
  const [isFetchingFiles, setIsFetchingFiles] = useState(false);
  const [fromDate, setFromDate] = useState(new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000).toISOString()); // Default from date to 90 days ago
  const [toDate, setToDate] = useState(new Date().toISOString()); // Default to date to now

  const fetchFilesData = async (from_date = fromDate, to_date = toDate) => {
    setIsFetchingFiles(true);
    try {
      const accessToken = await getAccessTokenSilently({
        audience: `https://revenueai.us.auth0.com/api/v2/`,
        scope: "openid profile email offline_access",
      });

    // Convert dates to UTC ISO strings
      const fromUTC = new Date(from_date).toISOString();
      const toUTC = new Date(to_date).toISOString();
  
      // API call for files data with date range
      const filesResponse = await axios.get("/api/getMyFiles", {
        headers: { "Authorization": `Bearer ${accessToken}` },
        params: { from_date: fromUTC, to_date: toUTC } // Include date range in API request
      });
  
      // Handle files response
      setFilesData(filesResponse.data);
    } catch (error) {
      console.error("Failed to fetch files:", error);
    } finally {
      setIsFetchingFiles(false);
    }
  };
  
  useEffect(() => {
    if (user) {
      fetchFilesData();
    }
  }, [getAccessTokenSilently, user?.email, fromDate, toDate]);

  return (
    <FilesContext.Provider value={{ filesData, setFilesData, isFetchingFiles, fetchFilesData, setFromDate, setToDate }}>
      {children}
    </FilesContext.Provider>
  );
};

export const useFilesData = () => useContext(FilesContext);