import React, { useState, useCallback, useMemo, Fragment } from "react";
import { startOfWeek, format } from "date-fns";
import { CSVLink } from "react-csv";

import { useFilesData } from "./contexts/FilesContext";
import { useSession } from "./contexts/SessionContext";
import ChatSidebar from "./ChatSidebar";
import UploadButton from "./UploadButton";
import {
  Badge,
  Button,
  Card,
  Callout,
  Metric,
  Text,
  DatePicker,
  Select,
  SelectItem,
  SearchSelect,
  SearchSelectItem,
  DateRangePicker,
  DateRangePickerValue,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  LineChart,
  AreaChart,
  BarList,
  Dialog as TremorDialog,
  DialogPanel,
} from "@tremor/react";
import { flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { job_names, cost_codes } from "./constants/Giant";
import { IconSpinner } from "./ui/Icons";
import { Dialog, Transition, Popover } from "@headlessui/react";
import { ArrowPathIcon, ArrowDownTrayIcon, DocumentPlusIcon, ChatBubbleLeftEllipsisIcon, XMarkIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon, ChevronUpIcon, TrashIcon, DocumentArrowDownIcon, TableCellsIcon, PencilSquareIcon } from "@heroicons/react/24/solid";
import { toTitleCase } from "./lib/utils";

const valueFormatter = function (number) {
  return "$ " + new Intl.NumberFormat("us").format(number).toString();
};

const baseCloudflareURL = "https://pub-5ea15ea0f0e54a449e2f0990c21b9f2b.r2.dev";

const Dashboard = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const { filesData, setFilesData, isFetchingFiles, fetchFilesData } = useFilesData();
  const { userInfo, apiFetch } = useSession();
  const [dateRange, setDateRange] = useState({
    from: new Date(Date.now() - 90 * 24 * 60 * 60 * 1000), // Default to last 90 days
    to: new Date(),
  });

  const columns = [
    {
      header: "Processing Status",
      accessorKey: "processing_status",
      enableSorting: true,
      cell: ({ getValue }) => (
        <Badge variant={getValue() === "COMPLETE" ? "success" : getValue() === "FAILED" ? "error" : "warning"} color={getValue() === "COMPLETE" ? "green" : getValue() === "FAILED" ? "red" : "yellow"}>
          {getValue() === "FAILED" ? "Missing Info" : getValue()}
        </Badge>
      ),
    },
    {
      header: "Approvals",
      accessorKey: "approvals",
      enableSorting: false,
      cell: ({ getValue }) => renderApprovalsInTable(getValue()),
    },
    {
      header: "Vendor Name",
      accessorKey: "vendor_name",
      enableSorting: true,
    },
    {
      header: "Upload Date",
      accessorKey: "upload_date",
      enableSorting: true,
      cell: ({ getValue }) => new Date(getValue()).toLocaleDateString(),
    },
    {
      header: "Invoice Date",
      accessorKey: "invoice_date",
      enableSorting: true,
      cell: ({ getValue }) => new Date(getValue()).toLocaleDateString(),
    },
    {
      header: "Job Name",
      accessorKey: "job_name",
      enableSorting: true,
    },
    {
      header: "Cost Code",
      accessorKey: "cost_code",
      enableSorting: true,
    },
    {
      header: "Invoice Number",
      accessorKey: "invoice_number",
      enableSorting: false,
    },
    {
      header: "Total Amount",
      accessorKey: "total_amount",
      enableSorting: true,
      cell: ({ getValue }) => parseFloat(getValue()).toFixed(2),
      meta: {
        align: "text-right",
      },
    },
  ];

  const renderOptionsPopover = (row) => (
    <Popover as="div" className="relative">
      <Popover.Button className="inline-flex justify-between w-full px-4 py-2 text-sm font-semibold text-gray-700 bg-gray-500 rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
        Options
        <ChevronDownIcon className="w-5 h-5 ml-1" aria-hidden="true" />
      </Popover.Button>
      <Transition
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel anchor="bottom start" className="flex flex-col z-10 bg-white">
          <div className="p-1 text-xs">
            <button className="group flex items-center w-full p-2 text-gray-900 hover:bg-gray-100 rounded-md" onClick={() => handleEdit(row.original)}>
              <PencilSquareIcon className="w-5 h-5 mr-3" aria-hidden="true" />
              Review Invoice
            </button>
            <button className="group flex items-center w-full p-2 text-gray-900 hover:bg-gray-100 rounded-md" onClick={() => window.open(`${baseCloudflareURL}/PDFs/${row.original.file_id}`, "_blank")}>
              <DocumentArrowDownIcon className="w-5 h-5 mr-3" aria-hidden="true" />
              Download PDF
            </button>
            <button className="group flex items-center w-full p-2 text-gray-900 hover:bg-gray-100 rounded-md" onClick={() => window.open(`${baseCloudflareURL}/XLSX/${row.original.file_id}.xlsx`, "_blank")}>
              <TableCellsIcon className="w-5 h-5 mr-3" aria-hidden="true" />
              Download XLSX
            </button>
            <button className="group flex items-center w-full p-2 text-gray-900 hover:bg-gray-100 rounded-md" onClick={() => handleDelete(row.original.file_id)}>
              <TrashIcon className="w-5 h-5 mr-3 text-red-500" aria-hidden="true" />
              Delete
            </button>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );

  const table = useReactTable({
    data: filesData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    initialState: {
      sorting: [{ id: "upload_date", desc: true }],
    },
  });

  const handleFileUpload = (uploadedFileKeys) => {
    console.log("Uploaded files:", uploadedFileKeys);
    fetchFilesData(dateRange.from.toISOString(), dateRange.to.toISOString()); // Refresh files list after upload
  };

  const handleBulkExport = () => {
    // First, create a map to track duplicate invoices
    const invoiceMap = new Map();

    // Create a set of all unique approver emails
    const approverSet = new Set();
    filesData.forEach((file) => {
      if (file.approvals) {
        file.approvals.forEach((approval) => approverSet.add(approval.user_email));
      }
    });
    const approvers = Array.from(approverSet);

    // First pass: identify duplicates
    filesData.forEach((file) => {
      const key = `${file.vendor_name}-${file.invoice_number}`;
      if (invoiceMap.has(key)) {
        invoiceMap.set(key, true); // Mark as duplicate
      } else {
        invoiceMap.set(key, false);
      }
    });

    // Second pass: create CSV data with duplicate flag and approval columns
    const csvData = filesData.map((file) => {
      const key = `${file.vendor_name}-${file.invoice_number}`;
      const baseData = {
        processing_status: file.processing_status,
        vendor_name: file.vendor_name,
        invoice_number: file.invoice_number,
        invoice_date: file.invoice_date ? new Date(file.invoice_date).toISOString().split("T")[0] : "",
        upload_date: file.upload_date ? new Date(file.upload_date).toISOString().split("T")[0] : "",
        job_name: file.job_name,
        cost_code: file.cost_code,
        total_amount: file.total_amount,
        Duplicate_Flag: invoiceMap.get(key).toString(),
      };

      // Add approval columns
      approvers.forEach((approver) => {
        const approval = file.approvals ? file.approvals.find((a) => a.user_email === approver) : null;
        baseData[`Approval_${approver}`] = approval ? new Date(approval.approved_at).toISOString().split("T")[0] : "";
      });

      return baseData;
    });

    return csvData;
  };

  const handleDateRangeChange = (value) => {
    setDateRange(value);
    if (value.from && value.to) {
      fetchFilesData(value.from.toUTCString(), value.to.toUTCString());
    }
  };

  const handleDelete = async (fileId) => {
    console.log(`Attempting to delete file with ID: ${fileId}`);
    try {
      const response = await fetch(`/api/deleteFile?file_id=${fileId}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        console.log("File deleted successfully:", data);
        fetchFilesData(dateRange.from.toISOString(), dateRange.to.toISOString());
      } else {
        console.error("Failed to delete file:", data.error);
      }
    } catch (error) {
      console.error("Error deleting file:", error);
    }
  };

  const handleEdit = (file) => {
    const currentFileData = filesData.find((f) => f.file_id === file.file_id) || file;
    setCurrentFile({ ...currentFileData });
    setEditModalOpen(true);
  };

  const handleFieldChange = (key, value) => {
    setCurrentFile((prev) => ({ ...prev, [key]: value }));
  };

  const submitUpdates = async () => {
    try {
      const response = await apiFetch({
        url: `/api/updateFile?file_id=${currentFile.file_id}`,
        method: "POST",
        data: currentFile,
      });

      if (response.status === 200) {
        console.log("Update successful:", response.data);
        fetchFilesData(dateRange.from.toISOString(), dateRange.to.toISOString());
        setEditModalOpen(false);
      } else {
        console.error("Failed to update file:", response.data.error);
      }
    } catch (error) {
      console.error("Error updating file:", error);
    }
  };

  // Extract column headers from the first file object keys
  const columnHeaders = filesData.length > 0 ? Object.keys(filesData[0]) : [];

  // Fields to exclude from the edit modal and columns to exclude from the table
  const excludedFields = ["file_id", "file_name", "approvals", "upload_date"];
  const excludedColumns = ["file_id", "file_name"];

  const renderField = (key, value) => {
    const commonProps = {
      value: value || "",
      onChange: (e) => handleFieldChange(key, e.target.value),
      className: "block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
    };

    switch (key) {
      case "vendor_name":
        return <input type="text" {...commonProps} />;
      case "invoice_date":
        return <DatePicker value={value ? new Date(value) : null} onValueChange={(date) => handleFieldChange(key, date ? date.toISOString() : null)} />;
      case "total_amount":
        return <input type="number" step="0.01" {...commonProps} />;
      case "invoice_number":
        return <input type="text" {...commonProps} />;
      case "job_name":
        return (
          <SearchSelect value={value} onValueChange={(newValue) => handleFieldChange(key, newValue)}>
            {job_names.map((jobName) => (
              <SearchSelectItem key={jobName} value={jobName}>
                {jobName}
              </SearchSelectItem>
            ))}
          </SearchSelect>
        );
      case "cost_code":
        return (
          <SearchSelect value={value} onValueChange={(newValue) => handleFieldChange(key, newValue)}>
            {cost_codes.map((costCode) => (
              <SearchSelectItem key={costCode} value={costCode}>
                {costCode}
              </SearchSelectItem>
            ))}
          </SearchSelect>
        );
      case "processing_status":
        return (
          <Select value={value} onValueChange={(newValue) => handleFieldChange(key, newValue)}>
            <SelectItem value="COMPLETE">Complete</SelectItem>
            <SelectItem value="FAILED">Missing Info</SelectItem>
            <SelectItem value="PENDING">Pending</SelectItem>
          </Select>
        );
      case "upload_date":
        return <input type="text" {...commonProps} disabled={true} />;
      default:
        return <input type="text" {...commonProps} />;
    }
  };

  const renderApprovalsInTable = (approvals) => {
    if (!approvals || approvals.length === 0) {
      return <span className="text-gray-400">No approvals</span>;
    }

    return (
      <ul className="space-y-1">
        {approvals.map((approval, index) => (
          <li key={index} className="flex items-center space-x-2">
            <CheckCircleIcon className="h-4 w-4 text-teal-500" />
            <span className="text-sm">{approval.user_email}</span>
          </li>
        ))}
      </ul>
    );
  };

  const handleApproval = async (fileId, revoke = false) => {
    // Get the current file from state
    const fileToUpdate = filesData.find((file) => file.file_id === fileId);
    if (!fileToUpdate) return;

    // Create an optimistically updated version of the file
    const updatedFile = {
      ...fileToUpdate,
      approvals: revoke ? fileToUpdate.approvals.filter((approval) => approval.user_email !== userInfo.email) : [...(fileToUpdate.approvals || []), { user_email: userInfo.email, approved_at: new Date().toISOString() }],
    };

    // Optimistically update the UI
    setFilesData((prevData) => prevData.map((file) => (file.file_id === fileId ? updatedFile : file)));
    setCurrentFile(updatedFile);

    try {
      // Perform the actual API call
      await apiFetch({
        url: "/api/newApproval",
        method: "POST",
        data: { file_id: fileId, revoke },
      });

      // If successful, no need to do anything else as UI is already updated
    } catch (error) {
      console.error("Error processing approval:", error);
      // Revert the optimistic update
      setFilesData((prevData) => prevData.map((file) => (file.file_id === fileId ? fileToUpdate : file)));
      setCurrentFile(fileToUpdate);
      // Optionally, show an error message to the user
    } finally {
      // Optionally, refresh the data in the background to ensure consistency
      fetchFilesData(dateRange.from.toISOString(), dateRange.to.toISOString());
    }
  };

  const renderApprovalSection = (file) => {
    const hasApproved = file.approvals?.some((approval) => approval.user_email === userInfo.email);

    return (
      <div className="mt-4">
        <Callout className="mt-4" title="Approval Status" color={file.approvals && file.approvals.length > 0 ? "teal" : "gray"}>
          {file.approvals && file.approvals.length > 0 ? (
            <ul className="space-y-2 mb-4">
              {file.approvals.map((approval, index) => (
                <li key={index} className="flex items-center space-x-2">
                  <CheckCircleIcon className="h-5 w-5 text-teal-500" />
                  <span>{approval.user_email}</span>
                  <span className="text-gray-500 text-sm">{new Date(approval.approved_at).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })}</span>
                </li>
              ))}
            </ul>
          ) : (
            <p className="mb-4">No approvals yet</p>
          )}
          <div className="flex items-center justify-between">
            <Badge color={hasApproved ? "green" : "gray"}>{hasApproved ? "You have approved" : "Pending your approval"}</Badge>
            <Button
              variant={hasApproved ? "secondary" : "primary"}
              color={hasApproved ? "orange" : "emerald"}
              icon={hasApproved ? XCircleIcon : CheckCircleIcon}
              onClick={() => handleApproval(file.file_id, hasApproved)}
              disabled={isFetchingFiles}
              size="sm"
            >
              {isFetchingFiles ? <IconSpinner className="w-4 h-4" /> : hasApproved ? "Revoke Approval" : "Approve"}
            </Button>
          </div>
        </Callout>
      </div>
    );
  };

  return (
    <div className="p-4 z-[100]">
      <ChatSidebar isOpen={sidebarOpen} onClose={() => setSidebarOpen(false)} />

      <TremorDialog open={editModalOpen} onClose={() => setEditModalOpen(false)} className="z-[100]">
        <DialogPanel className="max-w-7xl">
          <div className="px-4">
            <Dialog.Title className="text-lg font-medium leading-6 text-gray-900">Review Invoice Fields</Dialog.Title>
            <p className="mt-1 text-sm text-gray-600">Update any fields that were not extracted correctly by RevenueAI.</p>
          </div>
          <div className="flex mt-4">
            <div className="w-1/2 pr-4">
              {currentFile &&
                Object.entries(currentFile)
                  .filter(([key]) => !excludedFields.includes(key))
                  .map(([key, value]) => (
                    <div key={key} className="mt-4 grid grid-cols-1 px-4 gap-x-6 gap-y-4 sm:grid-cols-12">
                      <div className="sm:col-span-4">
                        <label htmlFor={key} className="block text-sm font-medium leading-6 text-gray-900">
                          {toTitleCase(key)}
                        </label>
                      </div>
                      <div className="sm:col-span-8">{renderField(key, value)}</div>
                    </div>
                  ))}
              {currentFile && renderApprovalSection(currentFile)}
            </div>
            <div className="w-1/2 pl-4">{currentFile && <iframe src={`${baseCloudflareURL}/PDFs/${currentFile.file_id}`} title="PDF Preview" className="w-full h-[600px] border-0" />}</div>
          </div>
          <div className="flex justify-center px-4 py-4">
            <Button
              variant="primary"
              className="flex items-center"
              onClick={() => {
                submitUpdates();
                setEditModalOpen(false);
              }}
            >
              Save & Close
            </Button>
          </div>
        </DialogPanel>
      </TremorDialog>

      <div className="text-2xl font-bold mb-4">
        <div className="text-2xl font-bold mb-4">
          <h1>
            {userInfo?.organization_name && `${userInfo.organization_name} `}
            Dashboard
          </h1>
        </div>
      </div>
      <div className="flex flex-wrap space-x-2 mb-4">
        <UploadButton onFileUpload={handleFileUpload} disabled={userInfo?.organization_id} />
        <Button variant="primary" icon={ArrowPathIcon} onClick={() => fetchFilesData(dateRange.from.toISOString(), dateRange.to.toISOString())} className="inline-flex items-center mb-2">
          Refresh Files
        </Button>
        <CSVLink
          data={handleBulkExport()}
          filename={"invoice_data.csv"}
          className="tremor-Button-root flex-shrink-0 inline-flex justify-center items-center group font-medium outline-none rounded-tremor-default shadow-tremor-input dark:shadow-dark-tremor-input border text-sm py-2 px-4 bg-tremor-brand dark:bg-dark-tremor-brand border-tremor-brand dark:border-dark-tremor-brand text-tremor-brand-inverted dark:text-dark-tremor-brand-inverted hover:bg-tremor-brand-emphasis dark:hover:bg-dark-tremor-brand-emphasis mb-2"
        >
          <ArrowDownTrayIcon className="h-5 w-5 mr-2" />
          Bulk Export
        </CSVLink>
        <Button
          variant="primary"
          //color="sky-950"
          icon={ChatBubbleLeftEllipsisIcon}
          onClick={() => setSidebarOpen(true)}
          className="inline-flex items-center mb-2"
          // disabled
        >
          Chat with RevenueAI
        </Button>
        <DateRangePicker className="max-w-sm mb-2" value={dateRange} placeholder="Custom range" onValueChange={handleDateRangeChange} enableYearNavigation={true} />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-3 gap-4 mb-4">
        <div className="col-span-1">
          <Card className="mx-auto" decoration="top" decorationColor="indigo">
            <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Total Spend Processed</p>
            <p className="text-3xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">
              {isFetchingFiles ? (
                <IconSpinner className="w-9 h-9" />
              ) : (
                "$" +
                filesData
                  .reduce((total, file) => total + parseFloat(file.total_amount || 0), 0)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              )}
            </p>
          </Card>
        </div>
        <div className="col-span-1">
          <Card className="mx-auto" decoration="top" decorationColor="indigo">
            <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Number of Invoices Processed</p>
            <p className="text-3xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">{isFetchingFiles ? <IconSpinner className="w-9 h-9" /> : filesData.filter((file) => file.invoice_number).length}</p>
          </Card>
        </div>
        <div className="col-span-1">
          <Card className="mx-auto" decoration="top" decorationColor="indigo">
            <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">Most Recent Invoice Processed</p>
            <p className="text-3xl text-tremor-content-strong dark:text-dark-tremor-content-strong font-semibold">
              {isFetchingFiles ? (
                <IconSpinner className="w-9 h-9" />
              ) : (
                filesData
                  .sort((a, b) => new Date(b.upload_date) - new Date(a.upload_date))
                  .map((file) =>
                    new Date(file.upload_date).toLocaleDateString("en-US", {
                      month: "long",
                      day: "numeric",
                      year: "numeric",
                    })
                  )[0] || "No invoices processed"
              )}
            </p>
          </Card>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 mb-4">
        <div className="col-span-1 lg:col-span-2">
          <Card>
            <h3 className="text-lg font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">Invoice Amounts by Week</h3>
            <AreaChart
              className="mt-4 h-72"
              data={filesData
                .map((file) => ({
                  week: format(startOfWeek(new Date(file.invoice_date)), "MM-dd-yyyy"),
                  amount: parseFloat(file.total_amount),
                }))
                .reduce((acc, { week, amount }) => {
                  const existingWeek = acc.find((item) => item.week === week);
                  if (existingWeek) {
                    existingWeek.amount += amount;
                  } else {
                    acc.push({ week, amount });
                  }
                  return acc;
                }, [])
                .sort((a, b) => new Date(a.week) - new Date(b.week))} // Sorting by week from earliest to latest
              index="week"
              intervalType="preserveStartEnd"
              yAxisWidth={85}
              //yAxisLabel="Invoice Amount"
              categories={["amount"]}
              colors={["indigo"]}
              valueFormatter={valueFormatter}
            />
          </Card>
        </div>
        <div className="col-span-1">
          <Card className="p-0 mx-auto">
            <div className="flex items-center justify-between border-b border-tremor-border p-6 dark:border-dark-tremor-border">
              <p className="font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">Top 5 Vendors by Invoice Amount</p>
              <p className="text-tremor-label font-medium uppercase text-tremor-content dark:text-dark-tremor-content">Amount</p>
            </div>
            <div className="overflow-hidden p-6">
              <BarList
                data={filesData
                  .reduce((acc, file) => {
                    const vendor = file.vendor_name;
                    const amount = parseFloat(file.total_amount);
                    const existing = acc.find((item) => item.name === vendor);
                    if (existing) {
                      existing.value += amount;
                    } else {
                      acc.push({ name: vendor, value: amount });
                    }
                    return acc;
                  }, [])
                  .sort((a, b) => b.value - a.value)
                  .slice(0, 5)}
                valueFormatter={(value) => `$ ${value.toFixed(2)}`}
                className="mx-auto max-w-sm"
              />
            </div>
          </Card>
        </div>
      </div>
      <div className="grid grid-cols-1 gap-4 mb-4">
        <div className="col-span-1">
          <Card>
            <div className="sm:flex sm:items-center sm:justify-between sm:space-x-10">
              <div>
                <h3 className="font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">My Invoices</h3>
                <p className="mt-1 text-tremor-default leading-6 text-tremor-content dark:text-dark-tremor-content">Invoices processed by RevenueAI for the selected date range. Upload new invoices to process them.</p>
              </div>
            </div>
            <Table className="mt-4">
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id} className="border-b border-tremor-border dark:border-dark-tremor-border">
                    <TableHeaderCell className="text-tremor-content-strong dark:text-dark-tremor-content-strong"></TableHeaderCell>
                    {headerGroup.headers.map((header) => (
                      <TableHeaderCell key={header.id} onClick={header.column.getToggleSortingHandler()} className={`text-tremor-content-strong dark:text-dark-tremor-content-strong ${header.column.getCanSort() ? "cursor-pointer select-none" : ""}`}>
                        <div className="flex items-center gap-2">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {header.column.getCanSort() && (
                            <div className="flex flex-col -space-y-1">
                              <ChevronUpIcon
                                className={`h-4 w-4 text-tremor-content dark:text-dark-tremor-content ${header.column.getIsSorted() === 'desc' ? 'opacity-30' : ''}`}
                              />
                              <ChevronDownIcon
                                className={`h-4 w-4 text-tremor-content dark:text-dark-tremor-content ${header.column.getIsSorted() === 'asc' ? 'opacity-30' : ''}`}
                              />
                            </div>
                          )}
                        </div>
                      </TableHeaderCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell className="text-right">{renderOptionsPopover(row)}</TableCell>
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id} className={`text-tremor-content-strong dark:text-dark-tremor-content-strong ${cell.column.columnDef.meta?.align || ""}`}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
